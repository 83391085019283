import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, noindex }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const titleTemplate = title === site.siteMetadata.title ? '%s' : `%s | ${site.siteMetadata.title}`;

  const metaDescription = description || site.siteMetadata.description

  // `noindex` が `true` の場合に `robots` メタタグを追加
  const robotsMeta = noindex ? { name: "robots", content: "noindex, nofollow" } : null;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        robotsMeta // `noindex` の場合のみ追加
      ].filter(Boolean).concat(meta)}
    />
  )
}

// `noindex` のデフォルト値を `false` に設定
SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  noindex: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  noindex: PropTypes.bool, // `noindex` を追加
}

export default SEO
